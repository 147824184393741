<template>
  <v-container fluid>
    <BackButton :handler="goToPromotion"/>
    <v-container fluid   no-gutters style="max-width: 95% !important;">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <h3 class="text-base font-semibold black-text ml-1"> Promotion Details</h3>
          </v-col>
        </v-row>


        <v-card  class="rounded shadow-0 bordered mt-2">
          <v-card-text class="pb-0 mt-2">
            <v-row dense class="border-bottom mb-2">

              <v-col md="7">
                <v-row dense>
                  <v-col cols="12" sm="12" md="12">
                    <label>Promotion Name*</label>
                    <v-text-field
                        v-model="promotion.name"
                        outlined
                        required
                        :rules="[(v) => !!v || 'Name is required']"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="hide-details"
                        validate-on-blur
                    ></v-text-field>
                  </v-col>
                </v-row>


                <v-row dense>
                  <v-col cols="6" sm="12" md="6">
                    <label>Start Date*</label>
                    <date-field
                        v-model="promotion.start_date"
                        class-name="q-text-field shadow-0"
                        :dense="true"
                        :hide-details="true"
                        label=""
                        :rules="[(v) => !!v || 'Start date is required']"
                        :backFill="
                      checkBackfillPermission(
                        $modules.promotions.management.slug
                      )
                    "
                    >
                    </date-field>
                  </v-col>
                  <v-col cols="6" sm="12" md="6">
                    <label>End Date*</label>
                    <date-field
                        v-model="promotion.end_date"
                        :rules="[(v) => !!v || 'End date is required']"
                        class-name="q-text-field shadow-0"
                        :dense="true"
                        :hide-details="true"
                        label=""
                        :backFill="
                      checkBackfillPermission(
                        $modules.promotions.management.slug
                      )
                    "
                    >
                    </date-field>
                  </v-col>
                </v-row>


                <v-row dense>
                  <v-col
                      v-if="
                            checkWritePermission(
                              $modules.promotions.singleUserPromo.slug
                            )
                          "
                      cols="12"
                      sm="6"
                      md="6"
                  >
                    <label for="" >Promotion Usage</label>
                    <v-radio-group
                        class="  mt-0 pt-0"
                        v-model="promotion.usage_type"
                        row
                        :disabled="promotion.id ? true : false"
                        :rules="[(v) => !!v || 'Usage type is required']"
                    >
                      <v-radio
                          label="General"
                          color="red"
                          value="G"
                          class="custom-radio"
                      ></v-radio>
                      <v-radio
                          label="Single-use"
                          color="red"
                          value="S"
                          class="custom-radio"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="8"
                      md="6"
                      v-if="promotion.usage_type == 'G'"
                  >

                    <label for="">Promotion Code*</label>
                    <v-text-field
                        v-model="promotion.promotion_code"
                        outlined
                        hide-details="auto"
                        class="q-text-field shadow-0"
                        dense
                        validate-on-blur
                        required
                        :rules="[
                              (v) => !!v || 'Promotion code is required',
                            ]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      md="2"
                      lg="2"
                      v-if="promotion.usage_type == 'S'"
                  >

                    <label for="" v-if="!promotion.id">Upload Emails</label>
                    <v-btn
                        small
                        block
                        color="white"
                        style="color:black; height:30px; margin-top:10px"
                        dark
                        :disabled="promotion.id ? true : false"
                        @click="openParticipantsDialogue = true"
                    >
                      Upload Emails
                      <v-icon right dark>
                        mdi-cloud-upload
                      </v-icon>
                    </v-btn>
                  </v-col>

                </v-row>

              </v-col>
              <v-col md="5">
                <div style="height: 225px ; " class="pl-10">
                  <image-uploader
                      @upload="
                  (data) => {
                    promotion.image = data;
                  }
                "
                      @remove="
                  () => {
                    promotion.image = null;
                  }
                "
                      :image_path="promotion.image_path"
                      :height="150"
                      defaultImage="promotion"
                      message-position="inside"
                  ></image-uploader>
                </div>
              </v-col>
              <v-col md="12" lg="12" sm="12">
                <p class="text-base text-blue font-bold" style="padding: 0; margin: 0">Eligibility:</p>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center mt-0">
              <v-col cols="12" sm="4" md="3" v-if="hasChildVenues" class="mb-4 pt-0" style="margin-top: -10px">
                <label for="">Select Venues </label>
                <v-autocomplete
                    :items="venues"
                    v-model="promotion.venues"
                    item-value="id"
                    item-text="name"
                    outlined
                    multiple
                    :rules="[(v) => v.length > 0 || 'Venue is required']"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    hide-details="auto"
                    dense
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggleSelect()">
                      <v-list-item-action>
                        <v-icon :color="promotion.venues.length > 0 ? 'teal darken-4' : ''">{{
                            getServiceIcon()
                          }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                      v-if="promotion.venues.length === venues.length"
                      v-slot:selection="{ index }"
                  >
                    <span v-if="index === 0">All Venues</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.name }}</span>
                    <span v-if="index === 1">, {{ item.name }}</span>
                    <span v-if="index === 2">, ...</span>
                  </template>
                </v-autocomplete>


              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-0">
                <v-radio-group
                    class="mt-0"
                    v-model="promotion.applied_for"
                    row
                    :readonly="hasChildVenues"
                    :rules="[(v) => !!v || 'Customer type is required']"
                >
                  <v-radio
                      label="All Customers"
                      color="red"
                      value="C"
                      class="custom-radio"
                  ></v-radio>
                  <v-radio
                      label="Members Only"
                      color="red"
                      value="M"
                      class="custom-radio"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="2" style="margin-top: -27px">
                <label  v-if="promotion.applied_for == 'M'">Memberships</label>
                <v-select
                    v-if="promotion.applied_for == 'M'"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    :items="memberships"
                    item-text="name"
                    item-value="membership_id"
                    multiple
                    hide-details=""
                    class="q-autocomplete shadow-0"
                    dense
                    validate-on-blur
                    v-model="promotion.membership_ids"
                    :rules="[(v) => !!v[0] || 'Membership is required']"
                ></v-select>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>

        <v-row class="mt-4" dense>
          <v-col cols="12">
            <h3 class="text-base font-semibold black-text ml-1"> Description</h3>
          </v-col>
          <v-col cols="12">
            <text-editor
                @complete="
                  (val) => {
                    setDescriptionContent(val);
                  }
                "
                :message="this.promotion.description"
            />
          </v-col>
        </v-row>

        <v-row dense class="mt-4">
          <v-col cols="12">
            <h3 class="text-base font-semibold black-text ml-1"> Promotion Benefits</h3>
          </v-col>

          <v-col cols="12" md="12">
            <v-card class="rounded shadow-0  bordered" v-if="promotion.benefits.length > 0">
              <v-card-text>
                <benefit-selection
                    outlined
                    v-for="(benefit, bi) in promotion.benefits"
                    :key="`b_${bi}`"
                    class="mb-4"
                    :benefitIndex="bi"
                    :product_type="benefit.product_type"
                    :params="benefit"
                    :has-child-venues="hasChildVenues"
                    :venues="venues"
                    @update="updateBenefit"
                    @delete="warningBenefitRemove"
                    @duplicate="duplicateBenefit"
                    @changeProductType="changeProductType"
                    source = "promotion"
                    :product-types="productTypes"
                >
                </benefit-selection>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn color="teal-color" class="shadow-0" dark  @click="addProductType()">+Add Benefit </v-btn>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-2 white--text blue-color"
              @click="addOrEditPromotions"
              height="44"
          >{{ promotion.id != null ? "Update" : "Save" }} Promotion</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-dialog
          v-model="openParticipantsDialogue"
          scrollable
          persistent
          max-width="600"
      >
        <v-card>

          <v-card-text class="border-bottom mt-3" >
            <v-row class="border-bottom">
              <v-col cols="12">
                <SvgIcon class="text-2xl font-semibold" text="Add Emails" style="color: black" >
                </SvgIcon>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-file-input
                    label="Customers list"
                    v-model="promotion.participant_document"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    outlined
                    required
                    background-color="#fff"
                    class="q-text-field shadow-0"
                >
                </v-file-input>
              </v-col>
              <v-col cols="12" md="2" lg="2">
                <v-btn
                    small
                    block
                    color="white"
                    style="color:black; height:30px; margin-top:10px"
                    dark
                    @click="downloadSampleFile()"
                >
                  Sample Xlsx
                  <v-icon right dark>
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" md="8" lg="8"
                ><v-radio-group
                  v-model="promotion.upload"
                  row
                  class="pl-4"
                  label="Participants"
                >
                  <v-radio label="Upload" value="true"></v-radio>
                  <v-radio label="Manual" value="false"></v-radio> </v-radio-group
              ></v-col>
            </v-row> -->

            <!-- <v-container v-else>
              <v-row v-for="(customer, k) in promotion.participants" :key="k">
                <v-col cols="12" md="4" lg="4">
                  <v-text-field
                    v-model="customer.name"
                    outlined
                    background-color="#fff"
                    :label="`Name*`"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                  <v-text-field
                    v-model="customer.email"
                    outlined
                    background-color="#fff"
                    :label="`Email*`"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn class="ma-2 white--text blue-color" @click="closeModal()"
              >Close</v-btn
            > -->
            <v-btn
                class="ma-2 white--text blue-color" @click="closeModal"
            >Done</v-btn
            >

          </v-card-actions>
        </v-card>
      </v-dialog>
      <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
      ></confirm-model>
    </v-container>
  </v-container>
</template>

<script>
import BenefitSelection from "@/components/Benefit/BenefitSelection.vue";
import BackButton from "@/components/Common/BackButton.vue";
import TextEditor from "@/components/Marketing/TextEditor.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {
    SvgIcon,
    ImageUploader,
    TextEditor,
    BackButton,
    BenefitSelection,
  },
  data() {
    return {
      openParticipantsDialogue: false,
      promotion: {
        upload: true,
        name: null,
        description: null,
        start_date: null,
        end_date: null,
        promotion_code: null,
        benefits: [],
        applied_for: "C",
        id: null,
        membership_ids: [],
        deleted_benefits: [],
        usage_type: "G",
        venues:[],
        // participants: [
        //   {
        //     name: null,
        //     email: null,
        //   },
        // ],
        participant_document: null,
        usage_count: 1,
      },
      venues:[],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      valid: true,
      hasChildVenues:false,
    };
  },
  mounted() {
    if(this.$store.getters.venueInfo.sub_venues > 0){
      this.hasChildVenues = true;
      this.getChildVenues();
      this.promotion.venues.push(this.$store.getters.userInfo.venue_id);
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getMemberships.status == false) {
      this.$store.dispatch("loadMemberships");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$route.params.data != null) {
      this.populateEditPromotion(atob(this.$route.params.data));
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    memberships() {
      return this.$store.getters.getMemberships.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypesCustom("Voucher");
    },
  },

  methods: {
    toggleSelect() {
      this.$nextTick(() => {
        if (this.promotion.venues.length == this.venues.length) {
          this.promotion.venues = [];
        } else {
          this.promotion.venues = this.venues.map((item) => item.id);
        }
      });
    },
    getServiceIcon() {
      if (this.promotion.venues.length == 0) return "mdi-checkbox-blank-outline";
      if (this.venues.length == this.promotion.venues.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },


    async getChildVenues() {
      await this.$http
          .get("venues/get-child-venues")
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let data = response.data.data;
              this.venues = data;
            }
          }).catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
            this.hideLoader();
          });
    },
    changeProductType(productType , benefitIndex){
      let type= this.productTypes.find((p)=>{
        return p.id == productType;
      })
      if(type){
        this.promotion.benefits[benefitIndex].product_type = type;
      }
    },

    setDescriptionContent(content) {
      if (this.promotion) {
        this.promotion.description = content;
      }
    },
    downloadSampleFile() {
      window.open("https://krewsqportalimageslive.blob.core.windows.net/qportalimageslive/import/promo-customers.xlsx", "_blank");
    },
    closeModal() {
      this.participant_document = null;
      this.openParticipantsDialogue = false;
    },

    async populateEditPromotion(id) {
      this.showLoader("Loading");
      await this.$http
        .get("venues/promotions/" + id)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let data = response.data.data;
            this.promotion.id = data.id;
            this.promotion.name = data.name;
            this.promotion.description = data.description;
            this.promotion.start_date = data.start_date;
            this.promotion.end_date = data.end_date;
            this.promotion.applied_for = data.applied_for;
            this.promotion.promotion_code = data.promotion_code;
            this.promotion.image_path = data.image_path;
            this.promotion.usage_type = data.usage_type;
            this.promotion.participants = data.participants;
            this.promotion.usage_count = data.usage_count
              ? data.usage_count
              : 1;

            this.promotion.deleted_benefits = [];

            this.promotion.venues = [];
            this.promotion.venues.push(data.venue_id);
            data.venues.forEach(v => {
              this.promotion.venues.push(v.venue_id);
            });

            if (data.applied_for == "M") {
              if (data.memberships.length)
                this.promotion.membership_ids = data.memberships.map(
                  (item) => item.membership_id
                );
            }
            let benefits = [];
            data.benefits.forEach((element) => {
              let benefit = {};
              benefit.benefit_type = element.benefit_type;
              benefit.benefit = element.benefit;
              benefit.id = element.id;
              benefit.is_specific = element.is_specific;
              benefit.product_type = element.product_type;
              benefit.product_type_id = element.product_type_id;
              benefit.venue_service_ids = element.services.map(
                (item) => item.venue_service_id
              );

              if(element.services[0] && element.services[0].venue_service){
                benefit.venue_id = element.services[0].venue_service.venue_id;
              }


              if (element.is_specific == 1) {
                benefit.venue_service_ids = benefit.venue_service_ids[0];
              }
              benefit.category_ids = element.categories.map(
                (item) => item.category_id
              );
              benefit.event_ids = element.events.map((item) => item.event_id);
              benefit.workshop_ids = element.workshops.map(
                (item) => item.workshop_id
              );
              benefit.membership_ids = element.memberships.map(
                (item) => item.membership_id
              );
              benefit.trainer_ids = element.trainers.map(
                (item) => item.trainer_id
              );
              benefit.product_ids = element.products.map(
                (item) => item.product_id
              );
              benefits.push(benefit);
            });
            this.promotion.benefits = benefits;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    addOrEditPromotions(e) {
      e.preventDefault();
      if (!this.$refs.form.validate()) {
        this.showError("Please enter all required fields");
        return;
      }

      if (this.promotion.benefits.length == 0) {
        this.showError("Please add benifit");
        return;
      }
      this.showLoader();
      this.promotion.benefits.forEach((element, index) => {
        if (!Array.isArray(element.venue_service_ids)) {
          this.promotion.benefits[index].venue_service_ids = [
            element.venue_service_ids,
          ];
        }
      });
      let formData = new FormData();
      if (
        this.promotion.image != null &&
        typeof this.promotion.image != "string"
      )
        formData.append("image", this.promotion.image);
      formData.append("name", this.promotion.name);
      if (this.promotion.description)
        formData.append("description", this.promotion.description);
      formData.append("start_date", this.promotion.start_date);
      formData.append("promotion_code", this.promotion.promotion_code);
      formData.append("end_date", this.promotion.end_date);
      formData.append("applied_for", this.promotion.applied_for);
      formData.append("usage_type", this.promotion.usage_type);
      formData.append("usage_count", this.promotion.usage_count);

      if (
        // this.booking.upload &&
        this.promotion.participant_document != null &&
        typeof this.promotion.participant_document != "string"
      ) {
        formData.append(
          "participant_document",
          this.promotion.participant_document
        );
      }

      if (this.promotion.membership_ids.length > 0) {
        this.promotion.membership_ids.forEach((id, pi) => {
          formData.append(`membership_ids[${pi}]`, id);
        });
      }
      if (this.promotion.venues.length > 0) {
        this.promotion.venues.forEach((id, pi) => {
          formData.append(`venue_ids[${pi}]`, id);
        });
      }
      if (this.promotion.deleted_benefits.length > 0) {
        this.promotion.deleted_benefits.forEach((id, index) => {
          formData.append(`deleted_benefits[${index}]`, id);
        });
      }
      this.promotion.benefits.forEach((benefit, index) => {
        if (benefit.id != null) {
          formData.append(`benefits[${index}][id]`, benefit.id);
        }
        formData.append(
          `benefits[${index}][product_type_id]`,
          benefit.product_type_id
        );

        if(benefit.venue_id){
          formData.append(`benefits[${index}][venue_id]`, benefit.venue_id);
        }

        formData.append(`benefits[${index}][is_specific]`, benefit.is_specific);
        formData.append(
          `benefits[${index}][benefit_type]`,
          benefit.benefit_type
        );
        formData.append(`benefits[${index}][benefit]`, benefit.benefit);
        if (benefit.is_specific == 1) {
          benefit.product_ids.forEach((id, pi) => {
            formData.append(`benefits[${index}][product_ids][${pi}]`, id);
          });
        }
        if (!Array.isArray(benefit.venue_service_ids)) {
          formData.append(
            `benefits[${index}][venue_service_ids][0]`,
            benefit.venue_service_ids
          );
        } else {
          benefit.venue_service_ids.forEach((id, vi) => {
            formData.append(`benefits[${index}][venue_service_ids][${vi}]`, id);
          });
        }
        if (benefit.category_ids != null && benefit.category_ids.length > 0) {
          benefit.category_ids.forEach((id, ci) => {
            formData.append(`benefits[${index}][category_ids][${ci}]`, id);
          });
        }
        if (benefit.event_ids != null && benefit.event_ids.length > 0) {
          benefit.event_ids.forEach((id, ei) => {
            formData.append(`benefits[${index}][event_ids][${ei}]`, id);
          });
        }
        if (benefit.workshop_ids != null && benefit.workshop_ids.length > 0) {
          benefit.workshop_ids.forEach((id, wi) => {
            formData.append(`benefits[${index}][workshop_ids][${wi}]`, id);
          });
        }
        if (benefit.trainer_ids != null && benefit.trainer_ids.length > 0) {
          benefit.trainer_ids.forEach((id, ti) => {
            formData.append(`benefits[${index}][trainer_ids][${ti}]`, id);
          });
        }
        if (
          benefit.membership_ids != null &&
          benefit.membership_ids.length > 0
        ) {
          benefit.membership_ids.forEach((id, mi) => {
            formData.append(`benefits[${index}][membership_ids][${mi}]`, id);
          });
        }
      });

      this.$http
        .post(
          `venues/promotions${
            this.promotion.id != null ? "/" + this.promotion.id : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message =
              this.promotion.id != null
                ? "Promotion details updated successfully"
                : "Promotion added successfully";
            this.showSuccess(message);
            this.$router.push({ name: "Promotions" });
            this.hideLoader();
            this.$store.dispatch("loadPromotions");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    warningBenefitRemove(data) {
      let index = data.benefitIndex;
      let pkg = this.promotion.benefits[index];
      if (
        pkg.id == null &&
        pkg.venue_service_ids == null &&
        pkg.benefit_type == null &&
        pkg.benefit == null
      ) {
        this.promotion.benefits.splice(index, 1);
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this benefit?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "benefit",
          data: { id: pkg.id },
        };
      }
    },
    duplicateBenefit(data) {
      let index = data.benefitIndex;
      const benefit = JSON.parse(
        JSON.stringify(this.promotion.benefits[index])
      );
      delete benefit.id;
      this.promotion.benefits.splice(index, 0, benefit);
    },
    confirmActions(data) {
      if (data.type == "benefit") {
        if (data.data.id != null) {
          this.promotion.deleted_benefits.push(data.data.id);
        }
        this.promotion.benefits.splice(data.id, 1);
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    addProductType() {
      let type = {...this.productTypes[1]};
      let data = {
        is_specific: 0,
        venue_service_ids: [],
        product_type: type,
        product_type_id: type.id,
      };
      if (type.name == "Academy" || type.name == "Event") {
        data.event_ids = [];
      } else if (type.name == "Facility") {
        data.category_ids = [];
      } else if (type.name == "Membership") {
        data.membership_ids = [];
      } else if (type.name == "Trainer") {
        data.trainer_ids = [];
      }
      this.promotion.benefits.push(data);
    },
    goToPromotion() {
      this.$router.push({ name: "Promotions" }, () => {});
    },
    updateBenefit(data) {
      let index = data.index.benefitIndex;
      let field = data.field;
      if (index != null && data) {
        if(field === 'product_type_id'){
          let type= this.productTypes.find((p)=>{
            return p.id == data.data;
          })
          if(type){
            this.promotion.benefits[index].product_type = type;
          }
        }
        this.promotion.benefits[index][field] = data.data;
      }
    },
  },
};
</script>

<style scoped>
.product_add_btn {
  margin: 0 58%;
}
</style>
